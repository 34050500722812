import { BasicEntity } from '@/api/types/misc'
import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'

import {
  FacilityConstruct,
  FacilityConstructId,
  NewFacilityConstruct,
  UseGetFacilityConstructs,
  UseGetFacilityConstructsBasic,
  UseCreateFacilityConstruct,
  UseDeleteFacilityConstruct,
  UseUpdateFacilityConstruct,
} from './types/facilityConstruct'

const VERSION = 'v1'
const RESOURCE = 'facility-constructs'

function useGetFacilityConstructs(): UseGetFacilityConstructs {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetFacilityConstructs['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetFacilityConstructsBasic(): UseGetFacilityConstructsBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateFacilityConstruct(): UseCreateFacilityConstruct {
  const axios = useAxios<PromiseType<ReturnType<UseCreateFacilityConstruct['createFacilityConstruct']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createFacilityConstruct(data: NewFacilityConstruct): Promise<FacilityConstruct> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createFacilityConstruct,
  }
}

function useUpdateFacilityConstruct(): UseUpdateFacilityConstruct {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateFacilityConstruct['updateFacilityConstructs']>>>({
    method: 'PUT',
  })

  function updateFacilityConstructs(id: FacilityConstructId, data: FacilityConstruct): Promise<FacilityConstruct> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateFacilityConstructs,
  }
}

function useDeleteFacilityConstruct(): UseDeleteFacilityConstruct {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteFacilityConstruct['deleteFacilityConstruct']>>>({
    method: 'DELETE',
  })

  function deleteFacilityConstruct(id: FacilityConstructId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteFacilityConstruct,
  }
}

export {
  useGetFacilityConstructs,
  useGetFacilityConstructsBasic,
  useCreateFacilityConstruct,
  useUpdateFacilityConstruct,
  useDeleteFacilityConstruct,
}
