import { BasicEntity } from '@/api/types/misc'
import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'
import { PromiseType } from '@/utils/types/PromiseType'

import {
  CostCenter,
  CostCenterId,
  NewCostCenter,
  UseGetCostCenters,
  UseGetCostCentersBasic,
  UseCreateCostCenter,
  UseDeleteCostCenter,
  UseUpdateCostCenter,
} from './types/costCenter'

const VERSION = 'v1'
const RESOURCE = 'cost-centers'

function useGetCostCenters(): UseGetCostCenters {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetCostCenters['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetCostCentersBasic(): UseGetCostCentersBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreateCostCenter(): UseCreateCostCenter {
  const axios = useAxios<PromiseType<ReturnType<UseCreateCostCenter['createCostCenter']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createCostCenter(data: NewCostCenter): Promise<CostCenter> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createCostCenter,
  }
}

function useUpdateCostCenter(): UseUpdateCostCenter {
  const axios = useAxios<PromiseType<ReturnType<UseUpdateCostCenter['updateCostCenter']>>>({ method: 'PUT' })

  function updateCostCenter(id: CostCenterId, data: CostCenter): Promise<CostCenter> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updateCostCenter,
  }
}

function useDeleteCostCenter(): UseDeleteCostCenter {
  const axios = useAxios<PromiseType<ReturnType<UseDeleteCostCenter['deleteCostCenter']>>>({ method: 'DELETE' })

  function deleteCostCenter(id: CostCenterId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deleteCostCenter,
  }
}

export { useGetCostCenters, useGetCostCentersBasic, useCreateCostCenter, useUpdateCostCenter, useDeleteCostCenter }
