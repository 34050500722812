
import { computed, defineComponent, PropType, reactive, Ref, ref, watchEffect } from '@vue/composition-api'
import { DataOptions } from 'vuetify'
import { mdiPlusCircleOutline, mdiMagnify } from '@mdi/js'
import { cloneDeep, camelCase as toCamelCase } from 'lodash-es'

import ListBox from '@/views/baseData/views/budget/components/ListBox.vue'
import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'

import { useNotify } from '@/store'

import { isAccount, isBst, isCorporateIdentifier, isFacilityConstruct, isRequired, isRkost } from '@/utils/validation'
import { handleError } from '@/utils/handleError'
import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetPspAccounts, useCreatePspAccount, useUpdatePspAccount, useDeletePspAccount } from '@/api/pspAccount'
import {
  useGetFacilityConstructs,
  useCreateFacilityConstruct,
  useUpdateFacilityConstruct,
  useDeleteFacilityConstruct,
  useGetFacilityConstructsBasic,
} from '@/api/facilityConstruct'
import { useGetAccounts, useCreateAccount, useUpdateAccount, useDeleteAccount } from '@/api/account'
import { useGetCostCenters, useCreateCostCenter, useUpdateCostCenter, useDeleteCostCenter } from '@/api/costCenter'
import {
  useGetCorporateIdentifiers,
  useCreateCorporateIdentifier,
  useUpdateCorporateIdentifier,
  useDeleteCorporateIdentifier,
} from '@/api/corporateIdentifier'
import { useGetEnumItemsBasic } from '@/api/enumItem'

import { PspAccount, NewPspAccount } from '@/api/types/pspAccount'
import { FacilityConstruct, NewFacilityConstruct } from '@/api/types/facilityConstruct'
import { Account, NewAccount } from '@/api/types/account'
import { CostCenter, NewCostCenter } from '@/api/types/costCenter'
import { CorporateIdentifier, NewCorporateIdentifier } from '@/api/types/corporateIdentifier'
import { PersonId } from '@/api/types/person'
import { Rights } from '@/api/types/right'
import { DATA_TYPE, FORM_FIELDS_ENUM } from '@/utils/types/formField'

type DataToEdit =
  | PspAccount
  | NewPspAccount
  | FacilityConstruct
  | NewFacilityConstruct
  | Account
  | NewAccount
  | CostCenter
  | NewCostCenter
  | CorporateIdentifier
  | NewCorporateIdentifier

enum ENTITIES_ENUM {
  PSP_ACCOUNTS = 'PSP_ACCOUNTS',
  FACILITY_CONSTRUCTS = 'FACILITY_CONSTRUCTS',
  ACCOUNTS = 'ACCOUNTS',
  COST_CENTERS = 'COST_CENTERS',
  CORPORATE_IDENTIFIERS = 'CORPORATE_IDENTIFIERS',
}

type T_ENTITIES = Record<
  keyof typeof ENTITIES_ENUM,
  (keyof PspAccount | keyof FacilityConstruct | keyof Account | keyof CostCenter | keyof CorporateIdentifier)[]
>

const ENTITIES: T_ENTITIES = {
  PSP_ACCOUNTS: ['id', 'name', 'description', 'abbreviation', 'tag', 'facilityConstruct'],
  FACILITY_CONSTRUCTS: ['id', 'aibNumber', 'description'],
  ACCOUNTS: ['id', 'shortName', 'description'],
  COST_CENTERS: ['id', 'bst', 'rkost', 'description'],
  CORPORATE_IDENTIFIERS: ['id', 'identifier', 'description'],
}

type TABLE_HEADERS = Record<keyof typeof ENTITIES, { text: string; value: string }[]>

export default defineComponent({
  name: 'Budget',
  components: {
    ListBox,
    CommonAutocomplete,
  },
  props: {
    filterById: {
      type: Number as PropType<PersonId>,
      default: null,
    },
  },
  setup: (props, { root }) => {
    const EXCLUDED_FORM_FIELDS = [
      'id',
      'createdBy',
      'updatedBy',
      'createdAt',
      'updatedAt',
      'links',
      'pspProfessionalUnitAssignments',
      'tenderings',
      'organizationalUnits',
      'corporateUnits',
      'title',
    ]

    const TABLE_HEADERS: TABLE_HEADERS = {} as TABLE_HEADERS

    function isSortable(value: string) {
      return ['name', 'shortName', 'aibNumber', 'bst', 'rkost', 'identifier', 'abbreviation'].includes(value)
    }

    function createTableHeaders(): void {
      Object.keys(ENTITIES).forEach((key) => {
        ENTITIES[key].forEach((value) => {
          if (!EXCLUDED_FORM_FIELDS.includes(value)) {
            TABLE_HEADERS[key] = [
              ...(TABLE_HEADERS[key]?.length ? TABLE_HEADERS[key] : []),
              {
                text: root.$t(`baseData.budget.${toCamelCase(key)}.table.col.title.${value}`),
                value,
                sortable: isSortable(value),
              },
            ]
          }
        })
      })
    }

    createTableHeaders()

    const { addNotification } = useNotify()

    const formElement = ref<null | HTMLFormElement>(null)
    const isAddEditDialogOpen = ref(false)
    const isEditMode = ref(false)
    const dataToEdit = ref<null | DataToEdit>(null)
    const activeEntity = ref<null | keyof typeof ENTITIES_ENUM>(null)
    const isFormValid = ref(false)

    function addData(entity: keyof typeof ENTITIES_ENUM): void {
      dataToEdit.value = {} as DataToEdit

      ENTITIES[entity].forEach((entry) => {
        if (!EXCLUDED_FORM_FIELDS.includes(entry)) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          dataToEdit.value![entry] = ''
        }
      })

      activeEntity.value = entity

      isAddEditDialogOpen.value = true
    }

    const { createPspAccount } = useCreatePspAccount()
    const { createFacilityConstruct } = useCreateFacilityConstruct()
    const { createCostCenter } = useCreateCostCenter()
    const { createAccount } = useCreateAccount()
    const { createCorporateIdentifier } = useCreateCorporateIdentifier()

    async function addDataXhr(entity: keyof typeof ENTITIES_ENUM): Promise<void> {
      try {
        switch (entity) {
          case ENTITIES_ENUM.PSP_ACCOUNTS:
            await createPspAccount(dataToEdit.value as NewPspAccount)
            await getPspAccounts()
            break
          case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
            await createFacilityConstruct(dataToEdit.value as NewFacilityConstruct)
            await getFacilityConstructs()
            break
          case ENTITIES_ENUM.COST_CENTERS:
            await createCostCenter(dataToEdit.value as NewCostCenter)
            await getCostCenters()
            break
          case ENTITIES_ENUM.ACCOUNTS:
            await createAccount(dataToEdit.value as NewAccount)
            await getAccounts()
            break
          case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
            await createCorporateIdentifier(dataToEdit.value as NewCorporateIdentifier)
            await getCorporateIdentifiers()
            break
        }

        addNotification({
          text: root.$t(`baseData.budget.${toCamelCase(entity)}.create.message`) as string,
          type: 'success',
        })

        closeDialog()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const { updatePspAccount, isLoading: isUpdatingPspAccount } = useUpdatePspAccount()
    const { updateFacilityConstructs, isLoading: isUpdatingFacilityConstruct } = useUpdateFacilityConstruct()
    const { updateCostCenter, isLoading: isUpdatingCostCenter } = useUpdateCostCenter()
    const { updateAccount, isLoading: isUpdatingAccount } = useUpdateAccount()
    const { updateCorporateIdentifier, isLoading: isUpdatingCorporateIdentifier } = useUpdateCorporateIdentifier()

    async function editDataXhr(entity: keyof typeof ENTITIES_ENUM): Promise<void> {
      try {
        switch (entity) {
          case ENTITIES_ENUM.PSP_ACCOUNTS: {
            let refinedData = { ...dataToEdit.value }

            Object.entries(refinedData as PspAccount).forEach(
              ([key, value]) => value && value.id && refinedData && (refinedData[key] = value.id)
            )

            await updatePspAccount((refinedData as PspAccount).id, refinedData as PspAccount)
            await getPspAccounts()
            break
          }
          case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
            await updateFacilityConstructs(
              (dataToEdit.value as FacilityConstruct).id,
              dataToEdit.value as FacilityConstruct
            )
            await getFacilityConstructs()
            break
          case ENTITIES_ENUM.COST_CENTERS:
            await updateCostCenter((dataToEdit.value as CostCenter).id, dataToEdit.value as CostCenter)
            await getCostCenters()
            break
          case ENTITIES_ENUM.ACCOUNTS:
            await updateAccount((dataToEdit.value as Account).id, dataToEdit.value as Account)
            await getAccounts()
            break
          case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
            await updateCorporateIdentifier((dataToEdit.value as Account).id, dataToEdit.value as CorporateIdentifier)
            await getCorporateIdentifiers()
            break
        }

        addNotification({
          text: root.$t(`baseData.budget.${toCamelCase(entity)}.edit.message`) as string,
          type: 'success',
        })

        closeDialog()
        isEditMode.value = false
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const { deletePspAccount } = useDeletePspAccount()
    const { deleteFacilityConstruct } = useDeleteFacilityConstruct()
    const { deleteCostCenter } = useDeleteCostCenter()
    const { deleteAccount } = useDeleteAccount()
    const { deleteCorporateIdentifier } = useDeleteCorporateIdentifier()

    async function deleteDataXhr(entity: keyof typeof ENTITIES_ENUM): Promise<void> {
      try {
        switch (entity) {
          case ENTITIES_ENUM.PSP_ACCOUNTS:
            await deletePspAccount((dataToEdit.value as PspAccount).id)
            await getPspAccounts()
            break
          case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
            await deleteFacilityConstruct((dataToEdit.value as FacilityConstruct).id)
            await getFacilityConstructs()
            break
          case ENTITIES_ENUM.COST_CENTERS:
            await deleteCostCenter((dataToEdit.value as CostCenter).id)
            await getCostCenters()
            break
          case ENTITIES_ENUM.ACCOUNTS:
            await deleteAccount((dataToEdit.value as Account).id)
            await getAccounts()
            break
          case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
            await deleteCorporateIdentifier((dataToEdit.value as CorporateIdentifier).id)
            await getCorporateIdentifiers()
            break
        }

        addNotification({
          text: root.$t(`baseData.budget.${toCamelCase(entity)}.delete.message`) as string,
          type: 'success',
        })

        closeDialog()
      } catch (error: unknown) {
        handleError(error)
      }
    }

    const { exec: getPspAccounts, data: pspAccounts, isLoading: isLoadingPspAccounts } = useGetPspAccounts()
    const {
      exec: getFacilityConstructs,
      data: facilityConstructs,
      isLoading: isLoadingFacilityConstructs,
    } = useGetFacilityConstructs()
    const { exec: getAccounts, data: accounts, isLoading: isLoadingAccounts } = useGetAccounts()
    const { exec: getCostCenters, data: costCenters, isLoading: isLoadingCostCenters } = useGetCostCenters()
    const {
      exec: getCorporateIdentifiers,
      data: corporateIdentifiers,
      isLoading: isLoadingCorporateIdentifiers,
    } = useGetCorporateIdentifiers()

    function closeDialog(): void {
      if (formElement.value) {
        formElement.value.reset()
        formElement.value.resetValidation()
      }

      isAddEditDialogOpen.value = false
      isEditMode.value = false

      activeEntity.value = null
      dataToEdit.value = null
    }

    function main(): void {
      if (hasSufficientRights(Rights.PSP_ACCOUNT_READ))
        getPspAccounts({ params: { personIds: props.filterById, size: 9999 } })
      if (hasSufficientRights(Rights.FACILITY_CONSTRUCT_READ))
        getFacilityConstructs({ params: { personIds: props.filterById, size: 9999 } })
      if (hasSufficientRights(Rights.ACCOUNT_READ))
        getAccounts({ params: { personIds: props.filterById, size: 9999 } })
      if (hasSufficientRights(Rights.COST_CENTER_READ))
        getCostCenters({ params: { personIds: props.filterById, size: 9999 } })
      if (hasSufficientRights(Rights.CORPORATE_IDENTIFIER_READ))
        getCorporateIdentifiers({ params: { personIds: props.filterById, size: 9999 } })

      activeEntity.value = null
    }
    main()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onClickRow([_, { item: row }], entity: keyof typeof ENTITIES_ENUM): void {
      if (hasSufficientUpdateRights(entity)) {
        isEditMode.value = true

        isAddEditDialogOpen.value = true

        activeEntity.value = entity

        dataToEdit.value = cloneDeep(row)
      }
    }

    function getLoadingState(entity: keyof typeof ENTITIES_ENUM): boolean {
      switch (entity) {
        case ENTITIES_ENUM.PSP_ACCOUNTS:
          return isUpdatingPspAccount.value || isLoadingPspAccounts.value
        case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
          return isLoadingFacilityConstructs.value || isUpdatingFacilityConstruct.value
        case ENTITIES_ENUM.ACCOUNTS:
          return isLoadingAccounts.value || isUpdatingAccount.value
        case ENTITIES_ENUM.COST_CENTERS:
          return isLoadingCostCenters.value || isUpdatingCostCenter.value
        case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
          return isLoadingCorporateIdentifiers.value || isUpdatingCorporateIdentifier.value
        default:
          return false
      }
    }

    const tableOptions: Ref<DataOptions[]> = ref([])

    function fillTableOptions(): void {
      for (const option in ENTITIES_ENUM) {
        switch (option) {
          case ENTITIES_ENUM.PSP_ACCOUNTS:
            tableOptions.value[option] = {
              page: 1,
              itemsPerPage: 5,
              sortBy: ['name'],
              sortDesc: [false],
              groupBy: [],
              groupDesc: [],
              multiSort: false,
              mustSort: true,
            }
            break
          case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
            tableOptions.value[option] = {
              page: 1,
              itemsPerPage: 5,
              sortBy: ['aibNumber'],
              sortDesc: [false],
              groupBy: [],
              groupDesc: [],
              multiSort: false,
              mustSort: true,
            }
            break
          case ENTITIES_ENUM.ACCOUNTS:
            tableOptions.value[option] = {
              page: 1,
              itemsPerPage: 5,
              sortBy: ['shortName'],
              sortDesc: [false],
              groupBy: [],
              groupDesc: [],
              multiSort: false,
              mustSort: true,
            }
            break
          case ENTITIES_ENUM.COST_CENTERS:
            tableOptions.value[option] = {
              page: 1,
              itemsPerPage: 5,
              sortBy: ['bst'],
              sortDesc: [false],
              groupBy: [],
              groupDesc: [],
              multiSort: false,
              mustSort: true,
            }
            break
          case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
            tableOptions.value[option] = {
              page: 1,
              itemsPerPage: 5,
              sortBy: ['identifier'],
              sortDesc: [false],
              groupBy: [],
              groupDesc: [],
              multiSort: false,
              mustSort: true,
            }
            break
        }
      }
    }
    fillTableOptions()

    const {
      data: facilityConstructsBasic,
      exec: getFacilityConstructsBasic,
      isLoading: isLoadingFacilityConstructsBasic,
    } = useGetFacilityConstructsBasic()

    const {
      exec: getEnumItemsBasic,
      data: enumItemsBasic,
      isLoading: isLoadingEnumItemsBasic,
    } = useGetEnumItemsBasic()

    watchEffect(() => {
      if (isAddEditDialogOpen.value && activeEntity.value === ENTITIES_ENUM.PSP_ACCOUNTS) {
        getFacilityConstructsBasic()
        getEnumItemsBasic({ params: { enumItemAssignment: 'PSP_ACCOUNT_TAG' } })
      }
    })

    function getForm(entity: keyof typeof ENTITIES_ENUM): Record<string, unknown>[] {
      switch (entity) {
        case ENTITIES_ENUM.PSP_ACCOUNTS:
          return [
            {
              value: 'name',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: true,
              rules: [
                (value: string) =>
                  isRequired(value, root.$t('baseData.budget.pspAccounts.table.col.title.name') as string),
              ],
            },
            {
              value: 'description',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: false,
              rules: [],
            },
            {
              value: 'abbreviation',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: true,
              rules: [
                (value: string) =>
                  isRequired(value, root.$t('baseData.budget.pspAccounts.table.col.title.abbreviation') as string),
              ],
            },
            {
              value: 'tag',
              fieldType: FORM_FIELDS_ENUM.DROPDOWN,
              items: computed(() => enumItemsBasic.value ?? []),
              isLoading: computed(() => isLoadingEnumItemsBasic.value),
              isRequired: false,
              rules: [],
            },
            {
              value: 'facilityConstruct',
              fieldType: FORM_FIELDS_ENUM.DROPDOWN,
              items: computed(() => facilityConstructsBasic.value ?? []),
              isLoading: computed(() => isLoadingFacilityConstructsBasic.value),
              isRequired: false,
              rules: [],
            },
          ]
        case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
          return [
            {
              value: 'aibNumber',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: true,
              rules: [
                (value: string) =>
                  isRequired(
                    value,
                    root.$t('baseData.budget.facilityConstructs.table.col.title.aibNumber') as string
                  ),
                (value: string) => isFacilityConstruct(value),
              ],
            },
            {
              value: 'description',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: false,
              rules: [],
            },
          ]
        case ENTITIES_ENUM.COST_CENTERS:
          return [
            {
              value: 'bst',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: true,
              rules: [
                (value: string) =>
                  isRequired(value, root.$t('baseData.budget.costCenters.table.col.title.bst') as string),
                (value: string) => isBst(value),
              ],
            },
            {
              value: 'rkost',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: true,
              rules: [
                (value: string) =>
                  isRequired(value, root.$t('baseData.budget.costCenters.table.col.title.rkost') as string),
                (value: string) => isRkost(value),
              ],
            },
            {
              value: 'description',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: false,
              rules: [],
            },
          ]
        case ENTITIES_ENUM.ACCOUNTS:
          return [
            {
              value: 'shortName',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: true,
              rules: [
                (value: string) =>
                  isRequired(value, root.$t('baseData.budget.accounts.table.col.title.shortName') as string),
                (value: string) => isAccount(value),
              ],
            },
            {
              value: 'description',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: false,
              rules: [],
            },
          ]
        case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
          return [
            {
              value: 'identifier',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: true,
              rules: [
                (value: string) =>
                  isRequired(
                    value,
                    root.$t('baseData.budget.corporateIdentifiers.table.col.title.identifier') as string
                  ),
                (value: string) => isCorporateIdentifier(value),
              ],
            },
            {
              value: 'description',
              fieldType: FORM_FIELDS_ENUM.TEXT,
              dataTyp: DATA_TYPE.TEXT,
              isRequired: false,
              rules: [],
            },
          ]
        default:
          return []
      }
    }

    function hasSufficientUpdateRights(entity: keyof typeof ENTITIES_ENUM): unknown {
      switch (entity) {
        case ENTITIES_ENUM.PSP_ACCOUNTS:
          return (
            hasSufficientRights(Rights.PSP_ACCOUNT_UPDATE) &&
            hasSufficientRights(Rights.FACILITY_CONSTRUCT_READ) &&
            hasSufficientRights(Rights.BASIC_READ)
          )
        case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
          return hasSufficientRights(Rights.FACILITY_CONSTRUCT_UPDATE)
        case ENTITIES_ENUM.ACCOUNTS:
          return hasSufficientRights(Rights.ACCOUNT_UPDATE)
        case ENTITIES_ENUM.COST_CENTERS:
          return hasSufficientRights(Rights.COST_CENTER_UPDATE)
        case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
          return hasSufficientRights(Rights.CORPORATE_IDENTIFIER_UPDATE)
        default:
          return false
      }
    }

    function hasSufficientAddRights(entity: keyof typeof ENTITIES_ENUM): unknown {
      switch (entity) {
        case ENTITIES_ENUM.PSP_ACCOUNTS:
          return (
            hasSufficientRights(Rights.PSP_ACCOUNT_CREATE) &&
            hasSufficientRights(Rights.FACILITY_CONSTRUCT_READ) &&
            hasSufficientRights(Rights.BASIC_READ)
          )
        case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
          return hasSufficientRights(Rights.FACILITY_CONSTRUCT_CREATE)
        case ENTITIES_ENUM.ACCOUNTS:
          return hasSufficientRights(Rights.ACCOUNT_CREATE)
        case ENTITIES_ENUM.COST_CENTERS:
          return hasSufficientRights(Rights.COST_CENTER_CREATE)
        case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
          return hasSufficientRights(Rights.CORPORATE_IDENTIFIER_CREATE)
        default:
          return false
      }
    }

    function hasSufficientReadRights(entity: keyof typeof ENTITIES_ENUM): unknown {
      switch (entity) {
        case ENTITIES_ENUM.PSP_ACCOUNTS:
          return hasSufficientRights(Rights.PSP_ACCOUNT_READ)
        case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
          return hasSufficientRights(Rights.FACILITY_CONSTRUCT_READ)
        case ENTITIES_ENUM.ACCOUNTS:
          return hasSufficientRights(Rights.ACCOUNT_READ)
        case ENTITIES_ENUM.COST_CENTERS:
          return hasSufficientRights(Rights.COST_CENTER_READ)
        case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
          return hasSufficientRights(Rights.CORPORATE_IDENTIFIER_READ)
        default:
          return false
      }
    }

    function hasSufficientDeleteRights(entity: keyof typeof ENTITIES_ENUM): unknown {
      switch (entity) {
        case ENTITIES_ENUM.PSP_ACCOUNTS:
          return hasSufficientRights(Rights.PSP_ACCOUNT_DELETE)
        case ENTITIES_ENUM.FACILITY_CONSTRUCTS:
          return hasSufficientRights(Rights.FACILITY_CONSTRUCT_DELETE)
        case ENTITIES_ENUM.ACCOUNTS:
          return hasSufficientRights(Rights.ACCOUNT_DELETE)
        case ENTITIES_ENUM.COST_CENTERS:
          return hasSufficientRights(Rights.COST_CENTER_DELETE)
        case ENTITIES_ENUM.CORPORATE_IDENTIFIERS:
          return hasSufficientRights(Rights.CORPORATE_IDENTIFIER_DELETE)
        default:
          return false
      }
    }

    function onAddEditData() {
      if (!activeEntity.value) return
      isEditMode.value ? editDataXhr(activeEntity.value) : addDataXhr(activeEntity.value)
    }

    const hasCancelButtonFocus = ref(false)

    return reactive({
      formElement,
      icons: {
        mdiPlusCircleOutline,
        mdiMagnify,
      },
      formRules: {
        isRequired,
      },
      constants: {
        Rights,

        ENTITIES_ENUM,
        ENTITIES,

        TABLE_HEADERS,

        FORM_FIELDS_ENUM,
      },
      state: {
        isAddEditDialogOpen,
        isEditMode,
        dataToEdit,
        activeEntity,
        isFormValid,
        tableOptions,

        pspAccounts,
        facilityConstructs,
        accounts,
        costCenters,
        corporateIdentifiers,

        hasCancelButtonFocus,
      },
      listeners: {
        onClickRow,

        onAddEditData,
      },
      functions: {
        toCamelCase,

        addData,

        deleteDataXhr,

        closeDialog,

        getLoadingState,

        getForm,

        hasSufficientAddRights,
        hasSufficientReadRights,
        hasSufficientUpdateRights,
        hasSufficientDeleteRights,
      },
    })
  },
})
