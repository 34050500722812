var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "align-self-start rounded-lg elevation-0",
    attrs: {
      "min-height": _vm.withSearch ? _vm.constants.BOX_HEIGHTS_5_ENTRIES.WITH_SEARCH : _vm.constants.BOX_HEIGHTS_5_ENTRIES.WITHOUT_SEARCH
    }
  }, [_c('v-card-text', [_vm._t("header"), _vm.withSearch ? _c('v-text-field', {
    staticStyle: {
      "width": "50%"
    },
    attrs: {
      "prepend-icon": _vm.icons.mdiMagnify,
      "dense": "",
      "outlined": "",
      "clearable": "",
      "hide-details": "",
      "placeholder": _vm.$t('misc.searchField.placeholder')
    },
    model: {
      value: _vm.state.searchInput,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "searchInput", $$v);
      },
      expression: "state.searchInput"
    }
  }) : _vm._e(), _c('v-data-table', _vm._g(_vm._b({
    attrs: {
      "height": "29vh",
      "fixed-header": "",
      "items-per-page": 5,
      "search": _vm.state.searchInput
    },
    scopedSlots: _vm._u([{
      key: "item.facilityConstruct",
      fn: function fn(_ref) {
        var _item$facilityConstru;

        var item = _ref.item;
        return [_vm._v(" " + _vm._s((_item$facilityConstru = item.facilityConstruct) === null || _item$facilityConstru === void 0 ? void 0 : _item$facilityConstru.name) + " ")];
      }
    }, {
      key: "item.tag",
      fn: function fn(_ref2) {
        var _item$tag;

        var item = _ref2.item;
        return [_vm._v(" " + _vm._s((_item$tag = item.tag) === null || _item$tag === void 0 ? void 0 : _item$tag.name) + " ")];
      }
    }], null, true)
  }, 'v-data-table', _vm.$attrs, false), _vm.$listeners)), _vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }