var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "my-5",
    attrs: {
      "fluid": _vm.$vuetify.breakpoint.lgAndDown && true
    }
  }, [_c('v-dialog', {
    attrs: {
      "value": _vm.state.isAddEditDialogOpen,
      "content-class": "rounded-lg elevation-2",
      "persistent": "",
      "max-width": "600px"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        return _vm.functions.closeDialog();
      }
    }
  }, [_c('v-card', {
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        _vm.state.hasCancelButtonFocus ? null : _vm.listeners.onAddEditData();
      }
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-h5"
  }, [_vm._v(" " + _vm._s(_vm.state.isEditMode ? _vm.$t('form.edit') : _vm.$t('form.add')) + " ")])]), _c('v-card-text', [_vm.state.dataToEdit ? _c('v-container', [_c('v-form', {
    ref: "formElement",
    attrs: {
      "lazy-validation": ""
    },
    model: {
      value: _vm.state.isFormValid,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "isFormValid", $$v);
      },
      expression: "state.isFormValid"
    }
  }, _vm._l(_vm.functions.getForm(_vm.state.activeEntity), function (entry) {
    var _entry$isLoading$valu, _entry$isLoading;

    return _c('v-row', {
      key: entry.value
    }, [_c('v-col', [entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.DROPDOWN ? _c('CommonAutocomplete', {
      class: entry.isRequired && 'required',
      attrs: {
        "items": entry.items.value,
        "item-text": "name",
        "item-value": "id",
        "label": _vm.$t("baseData.budget.".concat(_vm.functions.toCamelCase(_vm.state.activeEntity), ".table.col.title.").concat(entry.value)),
        "loading": (_entry$isLoading$valu = (_entry$isLoading = entry.isLoading) === null || _entry$isLoading === void 0 ? void 0 : _entry$isLoading.value) !== null && _entry$isLoading$valu !== void 0 ? _entry$isLoading$valu : false,
        "rules": [].concat(entry.rules)
      },
      model: {
        value: _vm.state.dataToEdit[entry.value],
        callback: function callback($$v) {
          _vm.$set(_vm.state.dataToEdit, entry.value, $$v);
        },
        expression: "state.dataToEdit[entry.value]"
      }
    }) : _c('v-text-field', {
      class: entry.isRequired && 'required',
      attrs: {
        "label": _vm.$t("baseData.budget.".concat(_vm.functions.toCamelCase(_vm.state.activeEntity), ".table.col.title.").concat(entry.value)),
        "rules": [].concat(entry.rules)
      },
      model: {
        value: _vm.state.dataToEdit[entry.value],
        callback: function callback($$v) {
          _vm.$set(_vm.state.dataToEdit, entry.value, $$v);
        },
        expression: "state.dataToEdit[entry.value]"
      }
    })], 1)], 1);
  }), 1)], 1) : _vm._e()], 1), _c('v-card-actions', [_vm.state.isEditMode && _vm.functions.hasSufficientDeleteRights(_vm.state.activeEntity) ? _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "color": "error"
    },
    on: {
      "click": function click($event) {
        return _vm.functions.deleteDataXhr(_vm.state.activeEntity);
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.delete')) + " ")]) : _vm._e(), _vm.state.isEditMode ? _c('v-spacer') : _vm._e(), _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.functions.closeDialog();
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('form.cancel')) + " ")]), !_vm.state.isEditMode ? _c('v-spacer') : _vm._e(), _vm.functions.hasSufficientAddRights(_vm.state.activeEntity) ? _c('v-btn', {
    staticClass: "rounded-lg",
    attrs: {
      "color": "primary",
      "elevation": "0",
      "loading": _vm.functions.getLoadingState(_vm.state.activeEntity)
    },
    on: {
      "click": function click($event) {
        return _vm.listeners.onAddEditData();
      },
      "focus": function focus($event) {
        _vm.state.hasCancelButtonFocus = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.state.isEditMode ? _vm.$t('form.save') : _vm.$t('form.add')) + " ")]) : _vm._e()], 1)], 1)], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, _vm._l(Object.keys(_vm.constants.ENTITIES).filter(function (entity) {
    return _vm.functions.hasSufficientReadRights(entity);
  }), function (entity, index) {
    return _c('v-col', {
      key: entity,
      attrs: {
        "cols": index === 0 ? '12' : '6',
        "xl": index === 0 ? '10' : '5'
      }
    }, [_c('ListBox', {
      attrs: {
        "headers": _vm.constants.TABLE_HEADERS[_vm.constants.ENTITIES_ENUM[entity]],
        "items": _vm.state[_vm.functions.toCamelCase(entity)],
        "loading": _vm.functions.getLoadingState(_vm.constants.ENTITIES_ENUM[entity]),
        "with-search": entity === _vm.constants.ENTITIES_ENUM.PSP_ACCOUNTS,
        "options": _vm.state.tableOptions[_vm.constants.ENTITIES_ENUM[entity]]
      },
      on: {
        "update:options": function updateOptions($event) {
          return _vm.$set(_vm.state.tableOptions, _vm.constants.ENTITIES_ENUM[entity], $event);
        },
        "dblclick:row": function dblclickRow() {
          var arguments$1 = arguments;

          for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
            args[_key] = arguments$1[_key];
          }

          return _vm.listeners.onClickRow(args, _vm.constants.ENTITIES_ENUM[entity]);
        }
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "mb-3 d-flex align-center"
          }, [_c('span', {
            staticClass: "text-h5"
          }, [_vm._v(" " + _vm._s(_vm.$t("baseData.budget.".concat(_vm.functions.toCamelCase(entity), ".title"))) + " ")]), _vm.functions.hasSufficientAddRights(_vm.constants.ENTITIES_ENUM[entity]) ? _c('v-btn', {
            staticClass: "ml-auto",
            attrs: {
              "icon": ""
            },
            on: {
              "click": function click($event) {
                return _vm.functions.addData(_vm.constants.ENTITIES_ENUM[entity]);
              }
            }
          }, [_c('v-icon', {
            staticClass: "primary--text"
          }, [_vm._v(" " + _vm._s(_vm.icons.mdiPlusCircleOutline) + " ")])], 1) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }