
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'

enum BOX_HEIGHTS_5_ENTRIES {
  WITHOUT_SEARCH = '417px',
  WITH_SEARCH = '457px',
}

export default defineComponent({
  name: 'ListBox',
  inheritAttrs: false,
  props: {
    withSearch: {
      type: Boolean,
      default: false,
    },
  },
  setup: () => {
    const searchInput = ref('')

    return reactive({
      icons: {
        mdiMagnify,
      },
      constants: {
        BOX_HEIGHTS_5_ENTRIES,
      },
      state: {
        searchInput,
      },
    })
  },
})
